<template>
  <div>
    <v-container class="text-center">
      <Additive
        v-if="additive"
      />
      <PathDependent
        v-else
      />
      <br>
    </v-container>
    <BottomBar/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import { scrollTop } from '@/utils/scroll';

export default {
  name: 'Test',
  components: {
    Additive: lazyLoad('views/Test/Additive'),
    PathDependent: lazyLoad('views/Test/PathDependent'),
    BottomBar: lazyLoad('components/Layout/BottomBar'),
  },
  computed: {
    ...mapGetters('testMacro', [
      'additive',
    ]),
    ...mapGetters('testMicro', [
      'activeStep',
    ]),
  },
  watch: {
    activeStep() {
      scrollTop();
    },
  },
};
</script>

<style>
.no-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
